 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="115px">
			<el-form-item class="el_form_item" label="系统类型">
				<el-select class="el_input" v-model="form.system" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="中物宝" value="1"></el-option>
					<el-option label="骏功物联" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="充值时间">
				<div class="block">
				<el-date-picker
					v-model="recharge_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
           </el-form-item>
		   	<el-form-item class="el_form_item">
			</el-form-item>
		    <div class="total_info item">
				<span>共:{{list.total}}条</span>
				<span>总计:{{total}}元</span>
			</div>
			<el-form-item label-width="0" style="margin-left: 30px;">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="系统类型" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.system_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="流水编号/充值时间" width="205px">
					<template slot-scope="scope">
						<div class="tab_line_item">充值编号: {{scope.row.sequence_code}}</div>
						<div class="tab_line_item">充值时间: {{scope.row.pay_time_text}}</div>
						<div class="tab_line_item" v-if="scope.row.create_time_text">处理时间: {{scope.row.create_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="用户信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.company_info.name}} / {{scope.row.company_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.company_info.name}}</div>
					</template>
				</el-table-column>
               <el-table-column label="付款信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.payment_company}}</div>
						<div class="tab_line_item">{{scope.row.payment_bank_name}}</div>
                        <div class="tab_line_item">{{scope.row.payment_account}}</div>
					</template>
				</el-table-column>
                <el-table-column label="收款信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.receipt_company}}</div>
						<div class="tab_line_item">{{scope.row.receipt_bank_name}}</div>
                        <div class="tab_line_item">{{scope.row.receipt_account}}</div>
					</template>
				</el-table-column>
				<el-table-column label="充值信息">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.much}} 元</div>
					</template>
				</el-table-column>
				<el-table-column label="处理状态">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.recharge_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="自动充值">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.tips}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="备注" prop="mark"></el-table-column> -->
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button v-if="scope.row.recharge !=2" size="mini" type="text" @click="recharge(scope.row)" style="margin-left: 20px">充值</el-button>
						<el-button v-if="scope.row.recharge !=2"  size="mini" type="text" @click="handle(scope.row)" style="margin-left: 20px">处理</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>
	</div>
	
</template>

<script>
	export default {
		data() {
			return {

				//搜索条件
				form: {				  
					start_date:'',//充值开始时间
					end_date:'',//充值结束时间
					system:'',//系统类型
				},
				recharge_value:[],//充值记录时间筛选
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				recharge_popup:{
					user_num:'',
					is_show:0
				}
			}
		},
		created() {
			
			//读取页面数据
			this.get_page_data()
		},
		methods: {
			handle(data){
				//提示
				this.$my.other.confirm({
					content:"是否确认处理完成?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'set_hsbank_recharge',
								user_num:data.company_info.user_num,
								much:data.much,
								payment_bank_name:data.payment_bank_name,
								payment_account:data.payment_account,
								receipt_bank_name:data.receipt_bank_name,
								receipt_account:data.receipt_account,
								sequence_code:data.sequence_code,
								pay_time:data.pay_time,
								system:data.system,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"处理成功"
								});
								//刷新
								this.get_page_data()
							}
						})
					},
				});
			},
			recharge(data){
				this.$my.other.confirm({
					content:"是否确认充值?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'hsbank_recharge_admin',
								user_num:data.company_info.user_num,
								much:data.much,
								payment_bank_name:data.payment_bank_name,
								payment_account:data.payment_account,
								receipt_bank_name:data.receipt_bank_name,
								receipt_account:data.receipt_account,
								sequence_code:data.sequence_code,
								pay_time:data.pay_time,
								system:data.system,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:"充值成功"
								});
								//刷新
								this.get_page_data()
							}
						})
					},
				});

			},
			//清空查询条件
			ser_para_clear(){
				this.form={
					start_date:'',//充值开始时间
					end_date:'',//充值结束时间
				}
				this.get_page_data()
			},
			//搜索
			page_ser(){
				if(this.recharge_value){
					this.form.start_date = parseInt(this.recharge_value[0] / 1000);
					this.form.end_date = parseInt(this.recharge_value[1] / 1000);
				}else{
                    this.form.start_date='',
					this.form.end_date=''
				}
				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'hsbank_recharge_list',
						is_get_app_user_info:1,
						is_get_company_info:1,
						is_get_admin_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{
                        //加载完毕
                        this.list.loading=false
                        if(data.msg.list.length ==0){
                            return
                        }
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.msg.list.length

						//预处理
						for(let item of data.msg.list){
							//下单时间
							item.pay_time_text=this.$my.other.totime(item.pay_time);

							//处理时间
							if(item.bank_recharge && item.bank_recharge.create_time>0){
								item.create_time_text=this.$my.other.totime(item.bank_recharge.create_time);
							}
							
							if(item.admin_recharge.length ==0){
								item.tips = '否'
							}else{
								item.tips = '是'
							}
							if(item.recharge==1){
                              item.recharge_text="未处理" 
							}else{
							  item.recharge_text="已处理" 
							}
							switch(item.system){
								case 1:item.system_text='中物宝';break;
								case 2:item.system_text='骏功物联';break;
							}
						}
						//渲染
						this.list.data=data.msg.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 19%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin-left: 0;
	}
</style>